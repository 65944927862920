// @import url("./theme.scss");

@font-face {
  font-family: "SfProBold";   /*Can be any text*/
  src: local("SfProBold"),
    url("../../../public/assets/fonts/sfpro/SF-Pro-Display-Bold.otf") format("truetype");
}

@font-face {
  font-family: "SfProMedium";   /*Can be any text*/
  src: local("SfProMedium"),
    url("../../../public/assets/fonts/sfpro/SF-Pro-Display-Medium.otf") format("truetype");
}

@font-face {
  font-family: "SfProRegular";   /*Can be any text*/
  src: local("SfProRegular"),
    url("../../../public/assets/fonts/sfpro/SF-Pro-Display-Regular.otf") format("truetype");
}

@font-face {
  font-family: "SfProRegularItalic";   /*Can be any text*/
  src: local("SfProRegularItalic"),
    url("../../../public/assets/fonts/sfpro/SF-Pro-Display-RegularItalic.otf") format("truetype");
}

a {
  text-decoration: none;
}

.mobile-menu.on {
  z-index: 99;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// .order-receipt-in 
//   .input.form-control, select.form-control {
//   min-height: 37px;
//   border: 1px solid grey !important;
//   margin-top : 3px !important;
//   }


.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #c7c3c3;
  background-color: #fafafa;
  color: #bdbdbd;

  margin-bottom: 20px;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}

.label {
  border-radius: 5px;
}

.label-eGift {
  background-color: #7E4300;
  color: #F7BF4F;
}

.label-delivery {
  background-color: #F7BF4F;
  color: #7E4300;
}

.float-right {
  float: right;
}

.mt--40 {
  margin-top: -40px;
}

.image-box img {
  border: 1px solid #b8b8b8;
  width: 130px;
  margin: auto;
  height: 130px;
}

.image-box {
  width: 150px;
  margin-bottom: 15px;
}

.image-section {
  display: contents;
}

.trash-image {
  color: red;
  position: absolute;
  margin-left: 99px;
  background: #fff;
  border-radius: 50%;
  padding: 0px 3px 3px 3px;
  width: 28px;
  margin-top: 5px;
}

.ml-20 {
  margin-left: 20px;
}


/* Order Reciept */
/* Order Reciept */
/* Order Reciept */
.order-reciept {
  display: flex;
  justify-content: center;
  font-family: 'SFProRegular';
  font-style: normal;

  .content {
    background-color: #F7BF4F;
    width: 414px;


    .main-title {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      font-family: 'SfProBold';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #7E4300;
    }

    .p-card {
      margin: 25px 50px;
      padding: 45px 5px;
      background-color: #fff;
      border: 1px solid #50555C;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      .p-item {
        display: flex;
        margin: 7px;

        img {
          width: 80px;
        }

        .p-content {
          display: block;
          align-self: center;
          padding: 5px;

          .p-name {
            font-family: 'SFProRegular';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.125581px;
            color: #50555C;
          }

          .p-desc,
          .p-qty {
            font-family: 'SFProRegular';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 12px;
            letter-spacing: 0.5px;
            color: #000000;
          }

          .p-qty {
            font-weight: 400;
          }
        }
      }
    }

    .w-card {
      margin: 25px 25px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #ABB0BC;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      color: #7E4300;
    }

    .redeem-card{
      background: #FFF4DF;
      border-radius: 10px;
      display: inline-block;
      box-shadow: none;
      border: 0px;
      margin: 20px 0px;
    }

    .redeeem-image-block{
      width: 15%;
      line-height: 60px;
      position: absolute;
    }

    .redeeem-image-block img{
      width: 30px;
    }

    .redeeem-text-block{
      width: 85%;
      float: right;
    }

    .big-card {
      margin: 0px 0px;
      margin-bottom: -60px;
      padding: 20px;
      background-color: #fff;
      box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 28px 28px 0px 0px;

      .title {
        margin-top: 10px;
        font-family: 'SfProBold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.125581px;
        color: #000000;
      }

      .redeem-at-div{
        margin-top: 15px !important;
        margin-bottom: 15px;
      }

      .Redemption-code-text {
        margin-top: 10px;
        font-family: 'SFProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.5px;
        color: #50555C;
      }

      .redeem-note-text{
        margin-top: 10px;
        margin-bottom: 40px;
        text-align: left;
        font-weight: 500;
        color: #7E4300;
      }
      .code-box {
        display: flex;
        justify-content: center;
        margin-top: 15px;

        .code-content {
          border: 1px solid #000;
          text-align: center;
          width: 60%;
          display: flex;
          justify-content: center;
          align-items: center;

          .code-text {
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: 0.5px;
            color: #7E4300;
            padding: 5px 5px;
          }
        }
      }


      .form {
        margin-top : 5px; 
        .form-info-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.408px;
          color: #50555C;
          font-family: 'SfProMedium';
        }


        .input-label {
          margin-top: 7px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.125581px;
          color: #000000;
          font-family: 'SfProMedium';
        }

        input,
        select {
          width: 100%;
          padding: 0px 5px;
          margin: 8px 0;
          box-sizing: border-box;
          border: none;
          border-bottom: 1px solid #ABB0BC;
        }

        .Receive-btn {
          margin-top: 14px;

          button {
            display: block;
            width: 100%;
            border: none;
            border-radius: 5px;
            background-color: #F7BF4F;
            padding: 14px 28px;
            cursor: pointer;
            text-align: center;
            font-weight: 700;
            font-size: 24px;
            line-height: 12px;
            letter-spacing: 0.5px;
            color: #7E4300;
            font-family: 'SfProBold';
          }
        }
      }

      .help-text {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.408px;
        color: #50555C;
        font-family: 'SfProBold';
      }

      .image-content {
        display: flex;
        justify-content: center;

        .img-text {
          width: auto;
        }

        .img-logo {
          width: 80px;
        }

      }


      //line
      .center-line {
        display: flex;
        flex-direction: row;
      }

      .center-line:before,
      .center-line:after {
        content: "";
        flex: 1 1;
        border-bottom: 2px solid #ABB0BC;
        margin: auto;
      }

      .center-line:before {
        margin-right: 10px
      }

      .center-line:after {
        margin-left: 10px
      }

    }


    .tgrey{
      color : #50555C;
    }
    .text-left{
      text-align: left;
    }
    .text-right{
      text-align: right;
    }

  

  }
}