

.error {
  color: #ff0000;
}

.input-label{
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.app-logo{
  width: 40px;
}
.login-logo{
  width: 60px;
}
.dashboard-title{
  font-size: 20px;
  font-weight: 600;
}
.dashboard-title i{
    color:#000000;
}
.mt-10{
  margin-top: 10px;
}
.mt-50{
  margin-top: 50px;
}